import { Injectable } from '@angular/core';
import { interval, Subject, switchMap, take } from 'rxjs';
import { AuthService } from '../auth.service';
import { interactionExpired } from './states/user/user.store';

@Injectable({ providedIn: 'root' })
export class InteractionService {
	private reset$: Subject<number> = new Subject();

	public showingWarning: boolean = false;

	private interactionTimerInitiated = false;

	private broadcastChannel = new BroadcastChannel('comerlat-interaction-channel');

	constructor(private authService: AuthService) {
		this.broadcastChannel.onmessage = event => {
			if (event.isTrusted) {
				this.reset$.next(event.data.time);
			} else {
				console.log('Detected untrusted message');
			}
			// this.reset$.next(event.time);
		};
	}

	public initializeInteractionTimer(): void {
		if (this.interactionTimerInitiated === false) {
			this.interactionTimerInitiated = true;
			console.log('Interaction timer initiated');
			this.reset$
				.pipe(
					switchMap(x => {
						console.log('Timer reset');
						return interval(x);
					}),
					take(1)
				)
				.subscribe(() => {
					this.interactionTimerInitiated = false;
					if (this.showingWarning === true) {
						interactionExpired();
						this.authService.logout();
					} else {
						this.showWarning();
					}
				});
		} else {
			this.reset();
		}
	}

	public reset() {
		this.reset$.next(600000);
		this.broadcastChannel.postMessage({ time: 600000 });
	}

	public continueSession() {
		this.reset();
		this.showingWarning = false;
	}

	private showWarning(): void {
		this.showingWarning = true;
		this.initializeInteractionTimer();
		this.reset$.next(60000);
		this.broadcastChannel.postMessage({ time: 60000 });
	}
}
