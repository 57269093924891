import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { of, shareReplay, switchMap, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { UserInfo } from './core/states/user/user-info';
import {
	isInteractionExpired,
	isUserAuthenticated,
	setUserAuthenticated,
	setUserInfo,
	userInfo$,
} from './core/states/user/user.store';

@Injectable({ providedIn: 'root' })
export class AuthService {
	public userInfo$ = userInfo$.pipe(
		switchMap((info: UserInfo | undefined) => {
			if (info != null) {
				return of(info);
			}
			return this.httpClient.get<UserInfo>(environment.apiUrl + '/auth/user-info').pipe(
				tap((info: UserInfo) => {
					setUserInfo(info);
				})
			);
		}),
		shareReplay()
	);
	constructor(
		private oauthService: OAuthService,
		private router: Router,
		private httpClient: HttpClient
	) {
		this.oauthService.configure(environment.authCodeFlowConfig);
		this.oauthService.tokenValidationHandler = new JwksValidationHandler();
		this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
			switch (type) {
				case 'token_received':
					if (!isUserAuthenticated()) {
						setUserAuthenticated(true);
						const url =
							this.oauthService.state == null
								? '/'
								: decodeURIComponent(this.oauthService.state);
						let navigation = url.split('/').filter(Boolean);
						if (navigation.length === 0) {
							navigation = ['/'];
						}
						this.userInfo$.subscribe();
						router.navigate(navigation);
					}

					break;
				case 'silently_refreshed':
					console.log('Token Refreshed');
					break;
				case 'silent_refresh_timeout':
					console.log('Silent Refresh Timeout');
					break;
				case 'discovery_document_loaded':
					this.oauthService.tokenEndpoint = environment.authCodeFlowConfig.tokenEndpoint;
					this.oauthService.revocationEndpoint =
						environment.authCodeFlowConfig.revocationEndpoint;
					break;
				case 'token_expires':
					console.log('Token Expires');
					if (isUserAuthenticated() && !isInteractionExpired()) {
						console.log('Token Refreshing');
						this.oauthService.refreshToken();
					}
					break;
				default:
					console.log(type);
			}
		});
	}

	public init(): Promise<OAuthEvent> {
		return this.oauthService.loadDiscoveryDocument();
	}

	public login(state: string): void {
		this.oauthService.initLoginFlow(state);
	}

	public logout(): void {
		this.oauthService.revokeTokenAndLogout({
			client_id: environment.authCodeFlowConfig.clientId,
			logout_uri: environment.authCodeFlowConfig.postLogoutRedirectUri,
		});
	}

	public callback(): void {
		this.oauthService
			.tryLoginCodeFlow()
			.then(x => {
				if (isUserAuthenticated()) {
					console.log('Just Chilling');
				} else {
					this.login('/');
				}
			})
			.catch(x => {
				console.error(x);
				this.router.navigate(['error', { err: 'Ocurrió un error al iniciar sesión' }], {
					replaceUrl: true,
				});
			});
	}

	public checkAndSetSessionActive(): boolean {
		let validToken = this.hasValidAccessToken();
		setUserAuthenticated(validToken);
		return validToken;
	}

	public hasValidAccessToken(): boolean {
		const expiresAt = this.oauthService.getAccessTokenExpiration() as number | null;
		return Date.now() < (expiresAt ?? 0) - (this.oauthService.clockSkewInSec ?? 0);
	}

	public getAccessToken(): string {
		return this.oauthService.getAccessToken();
	}
}
