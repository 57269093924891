import { AuthConfig } from 'angular-oauth2-oidc';

const authCodeFlowConfig: AuthConfig = {
	issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ahjsrdXHl',
	redirectUri: window.location.origin + '/login/callback',
	postLogoutRedirectUri: window.location.origin + '/logout',
	clientId: '44eera9tbmet9fe79bl3b3b3kn',
	responseType: 'code',
	scope: 'openid email',
	showDebugInformation: true,
	requireHttps: false,
	clearHashAfterLogin: true,
	strictDiscoveryDocumentValidation: false,
	tokenEndpoint: 'https://api.dev.serviciosintegralesdesalud.mx/auth',
	revocationEndpoint: 'https://api.dev.serviciosintegralesdesalud.mx/auth/revoke',
};

export const environment = {
	apiUrl: 'https://api.dev.serviciosintegralesdesalud.mx',
	authCodeFlowConfig: authCodeFlowConfig,
};
