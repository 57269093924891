import { createStore, select, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { UserInfo } from './user-info';

interface UserProps {
	authenticated: boolean;
	interactionExpired: boolean;
	info: UserInfo | undefined;
}

const UserStore = createStore(
	{ name: 'user' },
	withProps<UserProps>({ authenticated: false, info: undefined, interactionExpired: false })
);

export const userAuthenticated$: Observable<boolean> = UserStore.pipe(
	select(state => state.authenticated)
);

export const isUserAuthenticated = () => {
	return UserStore.state.authenticated;
};

export const userInfo$: Observable<UserInfo | undefined> = UserStore.pipe(
	select(state => state.info)
);

export const hasPermission$ = (permission: string) => {
	return UserStore.pipe(select(state => state.info?.frontActions.includes(permission)));
};

export function setUserAuthenticated(authenticated: boolean) {
	UserStore.update(state => ({
		...state,
		authenticated,
	}));
}

export function setUserInfo(userInfo: UserInfo) {
	UserStore.update(state => ({
		...state,
		info: userInfo,
	}));
}

export const isInteractionExpired = () => {
	return UserStore.state.interactionExpired;
};

export function interactionExpired() {
	UserStore.update(state => ({
		...state,
		interactionExpired: true,
	}));
}
